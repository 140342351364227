import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import Divider from "@material-ui/core/Divider"
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js"
import useWindowDimensions from "hooks/useWindowDimensions"

const useStyles = makeStyles(featuresStyle)

export default function HomeFooterContents() {
  const classes = useStyles()
  const { width } = useWindowDimensions()

  return (
    <footer
      style={{
        background: "inhert",
        width: "100%",
      }}>
      <div
        style={{
          background: "#fff",
          borderBottomRightRadius: "1rem",
          borderBottomLeftRadius: "1rem",
        }}>
        <GridContainer
          style={{
            background: "rgb(238, 238, 238)",
            width: "100%",
            margin: 0,
            display: "flex",
            justifyContent: "center",
          }}>
          <GridItem xs={12} sm={10} md={10}>
            <h3
              className={classes.title}
              style={{
                color: "#000",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}>
              ABOUT US
            </h3>
          </GridItem>
        </GridContainer>
        <GridContainer
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
          <GridItem
            xs={12}
            sm={5}
            md={5}
            style={{
              paddingLeft: "1rem",
              margin: "2rem 0",
            }}>
            <p
              style={{
                textAlign: "start",
                color: "#000",
                padding: "0 1rem",
                fontWeight: 400,
                fontSize: "12px",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              }}>
              <b>&apos;E-CHEOL AUTO PARTS&apos;</b> is an{" "}
              <b>auto parts wholesaler</b> located in <b>Seoul.</b>
              <br />
              We dream of a world where both companies and consumers connected
              through auto parts grow together.
            </p>
          </GridItem>
          <div
            style={
              width < 600
                ? {}
                : {
                    display: "flex",
                    justifyContent: "center",
                    margin: "2rem 0",
                  }
            }>
            <Divider orientation={width < 600 ? "horizontal" : "vertical"} />
          </div>
          <GridItem
            xs={12}
            sm={5}
            md={5}
            style={{
              paddingRight: "1rem",
              margin: "2rem 0",
            }}>
            <p
              style={{
                textAlign: "start",
                marginBottom: "4px",
                padding: "0 1rem",
                fontSize: "12px",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              }}>
              • Email: echeolauto6397@naver.com
            </p>
            <p
              style={{
                textAlign: "start",
                marginBottom: "4px",
                padding: "0 1rem",
                fontSize: "12px",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              }}>
              • Tel: +82 2 2216 6397
            </p>
            <p
              style={{
                textAlign: "start",
                marginBottom: "4px",
                padding: "0 1rem",
                fontSize: "12px",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              }}>
              • FAX: +82 2 2217 6378
            </p>
            <p
              style={{
                textAlign: "start",
                marginBottom: "4px",
                padding: "0 1rem",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontSize: "12px",
              }}>
              #13, Hwangmul-ro 17-gil, Dongdaemun-gu, Seoul, Republic of Korea
              (02619)
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </footer>
  )
}
